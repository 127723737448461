import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  closeMapFilters() {
    const mobileFilter = document.getElementById('mobile-filter')
    if (mobileFilter.classList.contains('activated')) {
      mobileFilter.classList.toggle('activated')
    }

    const filterDrawers = document.querySelectorAll('.filter .menu')
    filterDrawers.forEach(function(drawer){
      if ( drawer.classList.contains("show") ){
        drawer.classList.remove('show')
      }
    });
  }

  toggleMapOrProviderListForMobileView() {
    // TODO: Refactor when map is rebuilt
    const sidebarListOfProviders = document.querySelector(".sidebar");
    const viewListOrMap = document.getElementById('view-list-or-map');

    viewListOrMap.textContent = viewListOrMap.textContent === "View Map" ? "View List" : "View Map";
    sidebarListOfProviders.classList.toggle("list-expanded");
      if (sidebarListOfProviders.classList.contains("list-expanded")) {
        // Setting sidebarListOfProviders position for mobile view
        const contentOffsetTop = document.querySelector(".content").offsetTop;
        sidebarListOfProviders.style.top = `${contentOffsetTop}px`;
      } else {
        sidebarListOfProviders.style.top = "";
      }
  }
}
